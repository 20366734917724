import { accessModes } from './index';

export const apiSections = {
  accessMode: accessModes.NormalAccess,
  DMP_ACCESS_MODE_SECTION: 'accessMode',
  CONFIDENTIALITY_LEVEL_SECTION: 'confidentialityLevel',

  VITALE_CARD_SECTION: 'vitaleCard',
  VITALE_CARD_CHECK_SECTION: 'vitaleCardCheck',
  VITALE_SECTION: 'vitale',

  CONNECTOR_SECTION: 'connector',

  PCSC_READERS_SECTION: 'pcscReaders',

  CPX_CARD_SECTION: 'cpxCard',
  CPX_STATUS_SECTION: 'cpxStatus',
  CPX_SECTION: 'cpx',

  SESSION_STATE_SECTION: 'sessionState',
  SESSION_SECTION: 'session',

  REGISTER_DC_PARAMS: 'REGISTER_DC_PARAMS',
  UNREGISTER_DC_PARAMS: 'UNREGISTER_DC_PARAMS',
  IS_REGISTERED_DC_PARAMS: 'IS_REGISTERED_DC_PARAMS',

  INSC_TO_NIR: 'convert_insc_to_nir',
  INTEROPCODES: 'interOpCodes',
  CHECK_LICENSE_USER_RIGHT: 'checkUserLicenseRight',

  CPX_CARD_TYPE_SECTION: 'cpxCardType',
  VITALE_XML_CONTENT_SECTION: 'vitaleXmlContent',
  VITALE_XML_CONTENT_CHECK_SECTION: 'vitaleXmlContentCheck',

  SET_PERSISTANT_DATA: 'set_persistant_data',
  GET_PERSISTANT_DATA: 'get_persistant_data',
  CLEAR_PERSISTANT_DATA: 'clear_persistant_data',

  INSI_GET_INS: 'getInsCommands',
  INSI_CHECK_IDENTITY: 'checkIdentity',
  INSI_SUBMIT_IDENTITY_BATCH: 'submitIdentityBatch',
  INSI_RESULT_IDENTITY_BATCH: 'getIdentityBatchResult',
  INSI_EXPORT_IDENTITY_BATCH: 'insiBatchExport',
};
