import {
  apiErrors as apiErrorsBase,
  dmpErrors as dmpErrorsBase,
  jwtErrors as jwtErrorsBase,
  vitaleErrors as vitaleErrorsBase,
  softwareErrors as softwareErrorsBase,
} from 'dmpconnectjsapp-base/errors';

export const apiErrors = apiErrorsBase;
export const dmpErrors = dmpErrorsBase;
export const jwtErrors = jwtErrorsBase;
export const vitaleErrors = vitaleErrorsBase;
export const softwareErrors = {
  ...softwareErrorsBase,
  GET_REQUEST_FRAMES: 999,
};

export const jsonWenTokenErrorsToJwtErrors = {
  TokenExpiredError: jwtErrorsBase.EXPIRED_TOKEN,
  InvalidSignatureError: jwtErrorsBase.INVALID_SIGNATURE,
};
export const jwtIframeParentErrors = {
  [jwtErrorsBase.EXPIRED_TOKEN]: 'expired_token',
  [jwtErrorsBase.INVALID_SIGNATURE]: 'invalid_token_signature',
  [jwtErrorsBase.MISSING_CLAIMS]: 'missing_token_claims',
  [jwtErrorsBase.INVALID_TOKEN]: 'invalid_token',
};

export const tlsiErrors = {
  SIR_INFRASTRUCTURE_ERROR: 4096,
};

export const openIDErrors = {
  // auth
  invalid_client: 'invalid_client',
  invalid_uri: 'invalid_uri',
  redirect_uri_mismatch: 'redirect_uri_mismatch',
  not_allowed: 'not_allowed',
  consent_required: 'consent_required',

  // token
  invalid_request: 'invalid_request',
  unsupported_grant_type: 'unsupported_grant_type',
  invalid_grant: 'invalid_grant',
  unauthorized_client: 'unauthorized_client',
  invalid_scope: 'invalid_scope',
  invalid_redirect_uri: 'invalid_redirect_uri',
  invalid_token: 'invalid_token',
  login_check_failed: 'login_check_failed',
  missing_login_check: 'missing_login_check',
  unmatched_state: 'unmatched_state',
  unknown: 'unknown',
};
