import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { API_TYPES, apiSections } from 'dmpconnectjsapp-base/constants';
import { clearSection } from 'dmpconnectjsapp-base/actions';
import { getApiType, getConfigurationValue, getSessionId } from 'dmpconnectjsapp-base/helpers/accessors';
import { authenticationTypes, getAccessRightsProps } from 'dmpconnectjsapp-base/rules/accessRights';
import { isLoading, isReady } from 'dmpconnectjsapp-base/helpers/common';
import { requestPcscReaders } from '../../dmpconnect/actions';
import CenteredCard from '../Common/Card/CenteredCard';
import CenteredLoading from '../Common/Loading/CenteredLoading';

const CardReaderProvider = ({
  ready, loading, dispatch, apiType, children, authenticationType, esRestVitale, sessionId, applicationInitialized,
}) => {
  const [isReadersLoading, setReadersLoading] = React.useState(loading);
  useEffect(() => {
    if (ready) setReadersLoading(false);
  }, [ready]);

  useEffect(() => {
    if (
      applicationInitialized
      && !ready && !isReadersLoading
      && (
        (!!sessionId && apiType === API_TYPES.WS)
        || (apiType === API_TYPES.REST && esRestVitale)
      )
    ) {
      setReadersLoading(true);
      dispatch(clearSection(apiSections.PCSC_READERS_SECTION));
      dispatch(requestPcscReaders(false));
    }
  }, [apiType, esRestVitale, authenticationType, sessionId, ready, isReadersLoading, applicationInitialized]);

  if (
    !(!!sessionId && apiType === API_TYPES.WS && authenticationType !== authenticationTypes.INDIRECT)
  ) {
    return children;
  }
  if (ready) return children;
  return <CenteredCard><CenteredLoading message="Chargement ..." /></CenteredCard>;
};

CardReaderProvider.propTypes = {
  dispatch: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  ready: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  apiType: PropTypes.string.isRequired,
  authenticationType: PropTypes.number,
  esRestVitale: PropTypes.bool,
  applicationInitialized: PropTypes.bool,
  sessionId: PropTypes.string,
};

CardReaderProvider.defaultProps = {
  authenticationType: null,
  esRestVitale: false,
  applicationInitialized: false,
  sessionId: null,
};

const mapStateToProps = (state) => {
  const {
    dmpconnect: {
      [apiSections.PCSC_READERS_SECTION]: pcscReaders,
    },
    dmpconnectPersistedConnectorConfiguration,
    dmpconnectInit: {
      applicationInitialized,
    },
  } = state;
  const { accessRights: { authenticationType } } = getAccessRightsProps(state);

  return {
    applicationInitialized,
    ready: isReady(pcscReaders),
    loading: isLoading(pcscReaders),
    apiType: getApiType(state),
    authenticationType,
    esRestVitale: getConfigurationValue('esRestVitale', dmpconnectPersistedConnectorConfiguration),
    sessionId: getSessionId(state),
  };
};

export default connect(mapStateToProps)(CardReaderProvider);
