import base64 from 'base-64';

export function exportCpxInfo(cpxInfo, cpxInfoExportUrl) {
  return fetch(cpxInfoExportUrl, {
    method: 'post',
    body: JSON.stringify({
      file: {
        mime: '@file/json',
        data: base64.encode(JSON.stringify({
          cpxInfo,
        })),
      },
    }),
  });
}
