import merge from 'lodash.merge';

import { dmpconnectConfigurationActionConstants } from '../constants';
import { displayConfigFromEnv } from '../../reducers/configFromEnv';


export function dmpconnectDisplayConfiguration(state = displayConfigFromEnv, action) {
  switch (action.type) {
    case dmpconnectConfigurationActionConstants.DMPC_RESET_DISPLAY_CONFIGURATION_FROM_ENV:
      return action.payload;
    case dmpconnectConfigurationActionConstants.DMPC_SET_DISPLAY_CONFIGURATION:
      return merge({}, state, { [action.key]: action.value });
    default:
      return state;
  }
}
