import JSum from 'jsum';

const processEnv = typeof process !== 'undefined' ? process.env : {};
const injectedEnv = window && window.injectedEnv ? window.injectedEnv : {};
export const appConfig = window && window.appConfig ? window.appConfig : {};

const overridableParams = [
  'REACT_APP_DMP_SERVER_NAME',
  'REACT_APP_TLSI_SERVER_NAME',
  'REACT_APP_DMPCONNECTJS_PORT',
  'REACT_APP_DCPARAMS',

  'REACT_APP_API_TYPE',

  'REACT_APP_ES_REST_HOST',
  'REACT_APP_ES_REST_PORT',
  'REACT_APP_ES_REST_URL',

  'REACT_APP_ES',
  'REACT_APP_ES_DISABLE_CONFIG_PAGE',
  'REACT_APP_ES_LOCATION_NAME',
  'REACT_APP_ES_ACTIVITY_SECTOR',
  'REACT_APP_ES_PRACTICE_SETTING',
  'REACT_APP_ES_HEALTHCARE_SETTING',
  'REACT_APP_ES_LOGIN_TYPE',
  'REACT_APP_ES_AUTHENTICATION_MODE',
  'REACT_APP_ES_AUTHENTICATION_CONTEXT',
  'REACT_APP_ES_CPX_LOGIN_ACTIVE',
  'REACT_APP_ES_LOGIN_OPENID_CLIENT_ID',
  'REACT_APP_ES_LOGIN_OPENID_CLIENT_SECRET',
  'REACT_APP_ES_LOGIN_OPENID_SIGN_KEY',
  'REACT_APP_ES_LOGIN_OPENID_PKCE',
  'REACT_APP_ES_LOGIN_OPENID_AUTH_ENDPOINT',
  'REACT_APP_ES_LOGIN_OPENID_TOKEN_ENDPOINT',
  'REACT_APP_ES_LOGIN_OPENID_JWKS_ENDPOINT',
  'REACT_APP_ES_LOGIN_OPENID_USERINFOS_ENDPOINT',
  'REACT_APP_ES_LOGIN_OPENID_USERINFOS_METHOD',
  'REACT_APP_ES_LOGIN_OPENID_USERINFOS_MAPPER',
  'REACT_APP_ES_LOGIN_OPENID_USERINFOS',
  'REACT_APP_ES_LOGIN_OPENID_ACRVALUES',
  'REACT_APP_ES_LOGIN_OPENID_SCOPE',
  'REACT_APP_ES_LOGIN_CHECK_MAPPING',
  'REACT_APP_ES_LOGIN_CHECK',
  'REACT_APP_ES_LOGIN_CHECK_REQUIRED_VALUES',
  'REACT_APP_ES_JWT_KEY',
  'REACT_APP_ES_LOGIN_API_ENDPOINT',
  'REACT_APP_ES_LOGIN_API_ENDPOINT_PS_PARAM',
  'REACT_APP_ES_LOGIN_API_ENDPOINT_PATIENT_PARAM',
  'REACT_APP_API_LOGIN_MOCK',

  'REACT_APP_ES_CERT_INSI',
  'REACT_APP_ES_FINESS_INSI',
  'REACT_APP_ES_ID_INSI',
  'REACT_APP_ES_REST_ENABLE_VITALE_CARD_READING',

  'REACT_APP_HIDE_CONFIG',
  'REACT_APP_ACTIVATE_WS1',
  'REACT_APP_ACTIVATE_WS2',
  'REACT_APP_ACTIVATE_WS3',
  'REACT_APP_ACTIVATE_WS4',
  'REACT_APP_DEFAULT_WS',
  'REACT_APP_EXPORT_URL',
  'REACT_APP_BATCH_EXPORT_URL',
  'REACT_APP_BATCH_IMPORT_URL',
  'REACT_APP_CPX_INFO_EXPORT_URL',
  'REACT_APP_TSE',
  'REACT_APP_HIDE_COPY_INS_BUTTON',
  'REACT_APP_HIDE_COPY_ALL_BUTTON',
  'REACT_APP_HIDE_EXPORT_BUTTON',
  'REACT_APP_HIDE_NEW_SEARCH_BUTTON',
  'REACT_APP_HIDE_BACK_BUTTON',
  'REACT_APP_EXPORT_TO_LABEL',
  'REACT_APP_EXPORT_LABEL',
  'REACT_APP_AUTO_EXPORT_TO_SERVER',
  'REACT_APP_DO_NOT_EXPORT_TO_IFRAME_PARENT',

  'REACT_APP_INSI_ID_AM',
  'REACT_APP_INSI_NUM_AM',
  'REACT_APP_INSI_LPS_NAME',
  'REACT_APP_INSI_LPS_VERSION',

  'REACT_APP_GENERATED_CONNECTOR_JWT',

  'REACT_APP_MISSING_CONNECTOR_ERROR_MESSAGE', // message d'erreur custom sur l'écran indiquant que le connecteur est introuvable
  'REACT_APP_GET_TRANSACTION_FRAMES', // activer la récupération des trames
];
const filteredAppConfig = Object.assign(
  {},
  ...overridableParams.map(key => (appConfig[key] !== undefined ? ({ [key]: appConfig[key] }) : undefined)),
);
const env = {
  ...processEnv,
  ...injectedEnv,
  ...filteredAppConfig,
};

export const generateEnvCheckSum = currentEnv => JSum.digest(currentEnv, 'SHA256', 'hex');

export default env;
