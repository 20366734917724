import jwt, { JsonWebTokenError } from 'jsonwebtoken';
import { jwtErrors } from 'dmpconnectjsapp-base/errors';
import { createError, getErrorMessage } from '../errors';
import { errorTypes } from '../errors/errorConfiguration';
import { jsonWenTokenErrorsToJwtErrors, jwtIframeParentErrors } from '../errors/errorConstants';
import env from '../../envVariables';

export const getIframeParentJWTError = error => ({
  type: jwtIframeParentErrors[error.i_apiErrorCode],
  desc: getErrorMessage(error),
});

export function verifyAndDecodeJWT(token, getKey = null) {
  try {
    const decodedJwt = jwt.verify(
      token,
      getKey || env.REACT_APP_ES_JWT_KEY || '3s6v8y/B?E(H+MbQeThWmZq4t7w!z$C&',
    );
    const {
      hpGiven,
      hpInternalId,
      hpName,
      hpProfession,
      hpProfessionOid,
      hpSpeciality,
      hpAuthenticationMode,
      hpAuthenticationContext,
    } = decodedJwt;

    if (!hpGiven || !hpInternalId || !hpName || !hpProfession || !hpProfessionOid) {
      return {
        valid: false,
        error: createError(errorTypes.JWTErrors, jwtErrors.MISSING_CLAIMS),
      };
    }

    return {
      valid: true,
      values: {
        hpGiven,
        hpInternalId,
        hpName,
        hpProfession,
        hpProfessionOid,
        hpSpeciality,
        hpAuthenticationMode,
        hpAuthenticationContext,
        token,
      },
    };
  } catch (e) {
    let code = jsonWenTokenErrorsToJwtErrors[e.name] || jwtErrors.INVALID_TOKEN;
    if (e instanceof JsonWebTokenError && e.message === 'invalid signature') {
      code = jsonWenTokenErrorsToJwtErrors.InvalidSignatureError;
    }
    return {
      valid: false,
      error: createError(errorTypes.JWTErrors, code),
    };
  }
}
