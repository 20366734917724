import {
  actionChannel, call, delay, put, select, take,
} from 'redux-saga/effects';
import base64 from 'base-64';
import { getConfigurationValue } from 'dmpconnectjsapp-base/helpers/accessors';
import { getAccessRightsProps } from 'dmpconnectjsapp-base/rules/accessRights';
import {
  dmpCommandFailureContextualizedType,
  dmpCommandSuccess,
  dmpCommandSuccessContextualized,
  dmpCommandSuccessContextualizedType,
} from 'dmpconnectjsapp-base/actions';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import {
  getPersistantData, exportBatchResult,
  setPersistantData,
} from '../actions';
import { dmpconnectActionConstants } from '../constants';
import { generateNewPersistantData } from '../helpers/insi/batch';

const extractPersistantDataFromState = ({ dmpConnectPersistedAppConfiguration: { [apiSections.PERSISTANT_DATA_LS]: data } }) => data;

export const clearPersistantDataFromState = function* () {
  const actionData = { s_status: 'OK' };
  const actionContext = { section: apiSections.CLEAR_PERSISTANT_DATA };
  yield put(dmpCommandSuccess(actionData, actionContext));
  yield put(dmpCommandSuccessContextualized(actionData, actionContext));
};

export const getPersistantDataFromState = function* () {
  yield delay(500);
  let data = yield select(extractPersistantDataFromState);
  if (!(typeof data === 'string' || data instanceof String)) {
    data = '';
  }
  const actionData = {
    s_persistantData64: data,
    s_status: 'OK',
  };
  const actionContext = { section: apiSections.GET_PERSISTANT_DATA };
  yield put(dmpCommandSuccess(actionData, actionContext));
  yield put(dmpCommandSuccessContextualized(actionData, actionContext));
};

export const setPersistantDataToState = function* () {
  yield delay(500);
  const actionData = { s_status: 'OK' };
  const actionContext = { section: apiSections.SET_PERSISTANT_DATA };
  yield put(dmpCommandSuccess(actionData, actionContext));
  yield put(dmpCommandSuccessContextualized(actionData, actionContext));
};

const getBatchAutoProcess = ({ dmpConnectPersistedAppConfiguration }) => getConfigurationValue(
  'batchAutoProcess',
  dmpConnectPersistedAppConfiguration,
);

function* saveInPersistantData(parentBatchId, batch, clear, identities, exported) {
  const { accessRights: { psId } } = yield select(getAccessRightsProps);
  yield put(getPersistantData());
  const { data: { s_persistantData64 } } = yield take(dmpCommandSuccessContextualizedType(apiSections.GET_PERSISTANT_DATA));
  let newPersistantData;
  if (clear) {
    newPersistantData = generateNewPersistantData({
      psId,
      previousPersistantData: s_persistantData64,
      clear: true,
      exported,
    });
  } else {
    newPersistantData = generateNewPersistantData({
      psId,
      parentBatchId,
      batch,
      identities,
      exported,
      previousPersistantData: s_persistantData64,
    });
  }
  yield put(setPersistantData(base64.encode(JSON.stringify(newPersistantData))));


  const batchAutoProcess = yield select(getBatchAutoProcess);
  if (batchAutoProcess === true) {
    // if all identities done, export
    const {
      batches: {
        [psId]: {
          identities: identitiesResult,
          exported: alreadyExported,
        },
      },
    } = newPersistantData;
    const allIdsDone = identitiesResult.length > 0 && identitiesResult.every(i => [1, 2].includes(i.status));
    if (allIdsDone === true && !alreadyExported) {
      yield put(exportBatchResult(parentBatchId, identitiesResult));
    }
  }

  yield take([
    dmpCommandSuccessContextualizedType(apiSections.SET_PERSISTANT_DATA),
    dmpCommandFailureContextualizedType(apiSections.SET_PERSISTANT_DATA),
  ]);
}

export function* saveInPersistantDataQueue() {
  // 1- Create a channel for request actions
  const requestChan = yield actionChannel(dmpconnectActionConstants.DMPC_SAVE_BATCH_IN_PERSISTANT_DATA);
  while (true) {
    // 2- take from the channel
    const {
      parentBatchId, batch, clear, identities, exported,
    } = yield take(requestChan);
    // 3- Note that we're using a blocking call
    yield call(saveInPersistantData, parentBatchId, batch, clear, identities, exported);
  }
}
