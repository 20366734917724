export const getLoadingState = (action) => {
  const { context } = action;
  const { section, subSection } = context;
  if (subSection) {
    return {
      [section]: {
        [subSection]: {
          loading: true, updatedAt: null, error: null, tooLong: false,
        },
      },
    };
  }
  return {
    [section]: {
      loading: true, updatedAt: null, error: null, tooLong: false,
    },
    loading: true,
  };
};
export const getTooLongState = (action) => {
  const { context } = action;
  const { section, subSection } = context;
  if (subSection) {
    return {
      [section]: {
        [subSection]: {
          loading: true, updatedAt: Date.now(), tooLong: true,
        },
      },
    };
  }
  return {
    [section]: {
      loading: true, updatedAt: Date.now(), tooLong: true,
    },
  };
};
export const getSuccessState = (action) => {
  const { context, data } = action;
  const { section, subSection, params } = context;
  if (subSection) {
    return {
      [section]: {
        [subSection]: {
          ...data,
          loading: false,
          tooLong: false,
          params,
          updatedAt: Date.now(),
        },
      },
      loading: false,
    };
  }
  return {
    [section]: {
      ...data,
      updatedAt: Date.now(),
      loading: false,
      tooLong: false,
      params,
    },
    loading: false,
  };
};
export const getClearSectionState = (action, state) => {
  const { [action.section]: section, ...newState } = state;
  return { ...newState, [action.section]: {} };
};
export const getClearSubSectionState = (action, state) => {
  const { [action.section]: section, ...newState } = state;

  if (!section) return state;

  if (action.subSection in section) {
    const { [action.subSection]: subsection, ...newSubsections } = section;
    return { ...newState, [action.section]: { ...newSubsections } };
  }
  return state;
};

export const getClearState = (action, state) => {
  const { subSection } = action.context;
  if (subSection) return getClearSubSectionState(action.context, state);
  return getClearSectionState(action.context, state);
};

export const getFailureState = (action) => {
  const { context, data: error } = action;
  const { section, subSection, params } = context;
  if (subSection) {
    return {
      [section]:
        {
          [subSection]: {
            loading: false, tooLong: false, updatedAt: null, error, params,
          },
        },
      loading: false,
    };
  }
  return {
    [section]: {
      loading: false, tooLong: false, updatedAt: null, error, params,
    },
    loading: false,
  };
};
