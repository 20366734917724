import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';

export default function CenteredLoading({ message, children }) {
  return (
    <div className="centered-container">
      <Spinner animation="border" />
      {message !== null && <span className="mt-2">{message}</span>}
      {children}
    </div>
  );
}

CenteredLoading.defaultProps = {
  message: null,
  children: null,
};

CenteredLoading.propTypes = {
  children: PropTypes.node,
  message: PropTypes.string,
};
