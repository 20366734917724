import * as yup from 'yup';
import '../../utils/formUtils';

export const validationSchema = yup.object({
  name: yup.string().INSiNames().required('Ce champs est requis'),
  given: yup.string().INSiNames().required('Ce champs est requis'),
  birthday: yup.string().date().pastDate().required('Ce champs est requis'),
  birthplace: yup.string().DMPCMaxLength(5),
  sex: yup.number().oneOf([1, 2, 3]),
});

export const initialValues = {
  name: '',
  given: '',
  birthday: '',
  birthplace: '',
  sex: 1,
};
