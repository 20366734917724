import moment from 'moment';

import { dmpconnectActionConstants as dmpconnectActionConstantsConnector } from 'dmpconnectjsapp-base/constants';

export const PANELS = {
  BUTTONS: 'BUTTONS',
  FROM_VITALE_CARD: 'FROM_VITALE_CARD',
  MANUAL: 'MANUAL',
  VALIDATION: 'VALIDATION',
  VALIDATION_BATCH: 'VALIDATION_BATCH',
  RESULT: 'RESULT',
};

export const PANELS_INDEX = [
  PANELS.BUTTONS,
  PANELS.FROM_VITALE_CARD,
  PANELS.MANUAL,
  PANELS.VALIDATION,
  PANELS.VALIDATION_BATCH,
];

export const disabledOpCodes = ['EXP_PATIENT'];

export const dmpconnectActionConstants = {
  ...dmpconnectActionConstantsConnector,

  DMPC_REMOVE_DOC_FROM_PERISTANT_DATA: 'DMPC_REMOVE_DOC_FROM_PERISTANT_DATA',
  DMPC_SEND_DOC_TO_PERISTANT_DATA: 'DMPC_SEND_DOC_TO_PERISTANT_DATA',

  DMPC_GET_INS_FROM_VITALE_CARD_PROCESS: 'DMPC_GET_INS_FROM_VITALE_CARD_PROCESS',
  DMPC_ERROR_VITALE_CARD_CHANGED: 'DMPC_ERROR_VITALE_CARD_CHANGED',

  DMPC_SAVE_BATCH_IN_PERSISTANT_DATA: 'DMPC_SAVE_BATCH_IN_PERSISTANT_DATA',
  DMPC_INSI_CHECK_PENDING_BATCHES: 'DMPC_INSI_CHECK_PENDING_BATCHES',
  DMPC_INSI_EXPORT_RESULT: 'DMPC_INSI_EXPORT_RESULT',
  DMPC_INSI_STOP_ALL_GET_RESULTS: 'DMPC_INSI_STOP_ALL_GET_RESULTS',

  DMPC_VITALE_XML_PATIENTS: 'DMPC_VITALE_XML_PATIENTS',
};

export const dmpconnectMonitoringActionConstants = {
  DMPC_VITALE_MONITORING_START: 'DMPC_VITALE_MONITORING_START',
  DMPC_VITALE_MONITORING_STOP: 'DMPC_VITALE_MONITORING_STOP',
  DMPC_VITALE_MONITORING_UPDATE: 'DMPC_VITALE_MONITORING_UPDATE',

  DMPC_CPX_MONITORING_START: 'DMPC_CPX_MONITORING_START',
  DMPC_CPX_MONITORING_STOP: 'DMPC_CPX_MONITORING_STOP',
  DMPC_CPX_MONITORING_UPDATE: 'DMPC_CPX_MONITORING_UPDATE',
};

export const dmpconnectConfigurationActionConstants = {
  DMPC_SET_GLOBAL_CONFIGURATION: 'DMPC_SET_GLOBAL_CONFIGURATION',
  DMPC_SET_USER_CONFIGURATION: 'DMPC_SET_USER_CONFIGURATION',
  DMPC_SET_PERSIST_APP_CONFIGURATION: 'DMPC_SET_PERSIST_APP_CONFIGURATION',
  DMPC_SET_TSE_CONFIGURATION: 'DMPC_SET_TSE_CONFIGURATION',
  DMPC_SET_DISPLAY_CONFIGURATION: 'DMPC_SET_DISPLAY_CONFIGURATION',
  DMPC_RESET_DISPLAY_CONFIGURATION_FROM_ENV: 'DMPC_RESET_DISPLAY_CONFIGURATION_FROM_ENV',
  DMPC_RESET_ALL_CONFIGURATION_FROM_ENV: 'DMPC_RESET_ALL_CONFIGURATION_FROM_ENV',
  DMPC_RESET_PERSIST_APP_CONFIGURATION_FROM_ENV: 'DMPC_RESET_PERSIST_APP_CONFIGURATION_FROM_ENV',
};

export const dmpconnectApplicationActionConstants = {
  DMPS_SET_URL_PROCESSED: 'DMPS_SET_URL_PROCESSED',
  DMPC_DCPARAMS_UNREGISTERED: 'DMPC_DCPARAMS_UNREGISTERED',
  DMPC_DCPARAMS_REGISTERED: 'DMPC_DCPARAMS_REGISTERED',
  DMPC_INIT_APPLICATION: 'DMPC_INIT_APPLICATION',
  DMPC_RESET_APPLICATION: 'DMPC_RESET_APPLICATION',
  DMPC_INIT_APPLICATION_FAILURE: 'DMPC_INIT_APPLICATION_FAILURE',
  DMPC_INIT_APPLICATION_SUCCESS: 'DMPC_INIT_APPLICATION_SUCCESS',
  DMPC_SET_MODAL_ERROR: 'DMPC_SET_MODAL_ERROR',
  DMPC_CLEAR_MODAL_ERROR: 'DMPC_CLEAR_MODAL_ERROR',
  DMPC_SET_PANEL: 'DMPC_SET_PANEL',
  DMPC_CLOSE_MANAGE_DMP_MODAL: 'DMPC_CLOSE_MANAGE_DMP_MODAL',
  DMPC_SET_INTEROP_CODES: 'DMPC_SET_INTEROP_CODES',
  DMPC_SET_SOCKET: 'DMPC_SET_SOCKET',
  DMPC_SET_CONNECTOR_OK: 'DMPC_SET_CONNECTOR_OK',
  DMPC_SET_CONNECTOR_KO: 'DMPC_SET_CONNECTOR_KO',
  DMPC_SET_CONNECTOR_CONFIG_OK: 'DMPC_SET_CONNECTOR_CONFIG_OK',
  DMPC_SET_CONNECTOR_CONFIG_KO: 'DMPC_SET_CONNECTOR_CONFIG_KO',
  DMPC_SET_SESSION_OK: 'DMPC_SET_SESSION_OK',
  DMPC_SET_SESSION_KO: 'DMPC_SET_SESSION_KO',
  DMPC_SET_CONNECTOR_VERSION_KO: 'DMPC_SET_CONNECTOR_VERSION_KO',
  DMPC_SET_LICENSE_STATUS: 'DMPC_SET_LICENSE_STATUS',
  DMPC_START_VIRTUAL_PRINTER: 'DMPC_START_VIRTUAL_PRINTER',
  DMPC_DASHBOARD_INIT: 'DMPC_DASHBOARD_INIT',
  DMPC_DASHBOARD_INIT_OK: 'DMPC_DASHBOARD_INIT_OK',
  DMPC_LICENSE_CHECK: 'DMPC_LICENSE_CHECK',
  DMPC_LICENSE_AFTER_FORM_OK: 'DMPC_LICENSE_AFTER_FORM_OK',
  DMPC_LICENSE_CHECK_LOADING: 'DMPC_LICENSE_CHECK_LOADING',
  DMPC_SET_EXPORT_ID: 'DMPC_SET_EXPORT_ID',
  SET_IS_FIRST_LOGIN: 'SET_IS_FIRST_LOGIN',
  SET_LOGIN_TAB: 'SET_LOGIN_TAB',
};

export const dmpconnectUserActionConstants = {
  EXPIRED_SESSION: 'EXPIRED_SESSION',
  DMPC_LOGIN: 'DMPC_LOGIN',
  DMPC_LOGOUT: 'DMPC_LOGOUT',
  DMPC_LOGIN_SUCCESS: 'DMPC_LOGIN_SUCCESS',
  DMPC_LOGOUT_SUCCESS: 'DMPC_LOGOUT_SUCCESS',
  DMPC_SET_LOGIN_REFERER: 'DMPC_SET_LOGIN_REFERER',
  DMPC_CLEAR_LOGIN_REFERER: 'DMPC_CLEAR_LOGIN_REFERER',
  DMPC_SET_LOGIN_STEP_TWO: 'DMPC_SET_LOGIN_STEP_TWO',
  DMPC_CHECK_ID_PARAMS: 'DMPC_CHECK_ID_PARAMS',
  DMPC_CHECK_ID_PARAM: 'DMPC_CHECK_ID_PARAM',
  DMPC_SET_INS_PARAMS: 'DMPC_SET_INS_PARAMS',
  DMPC_SET_INS_PARAM: 'DMPC_SET_INS_PARAM',
  DMPC_ES_LOGIN: 'DMPC_ES_LOGIN',
  DMPC_SET_ES_USER: 'DMPC_SET_ES_USER',
  DMPC_SET_USER_JWT: 'DMPC_SET_USER_JWT',
  DMPC_ES_LOGOUT: 'DMPC_ES_LOGOUT',
  DMPC_ES_LOGIN_FAILED: 'DMPC_ES_LOGIN_FAILED',
  DMPC_ES_LOGIN_SUCCESS: 'DMPC_ES_LOGIN_SUCCESS',
  SET_PIN_CODE: 'SET_PIN_CODE',
};

export const dmpconnectAPIConstants = {
  DMPC_ACCESSIBLE_DMP_LIST_TYPE_LAST_AUTHORIZATION: 'LASTAUTORIZATION',
  DMPC_ACCESSIBLE_DMP_LIST_TYPE_LAST_DOC: 'LASTDOC',
  DMPC_AUTHORIZATIONS_LIST_ALL: 1,
  DMPC_AUTHORIZATIONS_LIST_AUTHORIZED: 2,
  DMPC_AUTHORIZATIONS_LIST_BLOCKED: 3,
};

export const dmpconnectHashes = {
  REDIRECT_TO_LAST_VSM_OR: '#redirect-to-last-vsm-or-hr',
  SECRET_CONNEXION: '#secret',
};

export const dmpconnectSetTreatingPhysicianActions = [
  dmpconnectActionConstants.DMPC_CREATE_DMP_AND_OTP,
  dmpconnectActionConstants.DMPC_CREATE_DMP,
  dmpconnectActionConstants.DMPC_REACTIVATE_DMP,
];

export const dmpconnectGeneratePDFActions = [
  dmpconnectActionConstants.DMPC_REACTIVATE_DMP,
  dmpconnectActionConstants.DMPC_EDIT_DMP,
];

export const userAuthorizationStatuses = {
  AuthorizationError: 1,
  AuthorizationExist: 2,
  AuthorizationExpired: 3,
  AuthorizationDenied: 4,
  NoAuthorization: 5,
};

export const userAuthorizationStatusMessages = {
  [userAuthorizationStatuses.AuthorizationError]: 'Le DMP est clos ou n\'existe pas',
  [userAuthorizationStatuses.AuthorizationExist]: 'Vous disposez d\'une autorisation d\'accès à ce DMP',
  [userAuthorizationStatuses.AuthorizationExpired]: 'L\'autorisation d\'accès a expiré',
  [userAuthorizationStatuses.AuthorizationDenied]: 'Accès refusé',
  [userAuthorizationStatuses.NoAuthorization]: 'Vous ne disposez d\'aucune autorisation pour accéder à ce DMP',
};

export const dmpStatuses = {
  DMPExist: 1,
  DMPIsClosed: 2,
  DMPNotFound: 3,
  DMPError: 4,
};

/**
 * @brief Document confidentiality visibility types.
 */
export const documentVisibility = {
  Normal: 1,
  PatientHidden: 2,
  HealthcareProfesionnalHidden: 4,
};

/**
 * @type {{AddAuthorization: number, RemoveAuthorization: number}}
 */
export const userAuthorizationAction = {
  AddAuthorization: 1,
  RemoveAuthorization: 2,
};

/**
 * @type {{NormalAccess: number, BrisDeGlace: number, Centre15: number}}
 */
export const accessModes = {
  NormalAccess: 1,
  BrisDeGlace: 2,
  Centre15: 3,
};

export const documentFormats = {
  textPlain: 1,
  richTextFormat: 2,
  jpeg: 3,
  tiff: 4,
  pdf: 5,
  vsm: 6,
  biologicalReport: 7,
};

export const documentFormatsArray = Object.entries(documentFormats).map(entry => entry[1]);

export const documentFormatTypeCodes = {
  [documentFormats.vsm]: ['SYNTH'],
};

export const cardStatuses = {
  NotInitialized: 0,
  ValidCardInserted: 1,
  NoCardInserted: 2,
  UnknownCardInserted: 3,
  CardStatusChanged: 4,
  CardIsBlocked: 5,
  CardIsExpired: 6,
  CpxReaderError: 7,
};

export const readerSlotTypes = {
  NONE: 1,
  VITALE: 2,
  CPS: 3,
  UNKNOWN: 4,
  ERROR: 5,
};
export const readerSlotTypesLabel = {
  [readerSlotTypes.NONE]: 'Aucune carte',
  [readerSlotTypes.VITALE]: 'Carte Vitale',
  [readerSlotTypes.CPS]: 'Carte CPS',
  [readerSlotTypes.UNKNOWN]: 'Carte inconnue',
  [readerSlotTypes.ERROR]: 'Détection impossible',
};


// TODO refactoring put format description in one single object
export const documentFormatNames = {
  [documentFormats.textPlain]: 'Texte',
  [documentFormats.richTextFormat]: 'RTF',
  [documentFormats.jpeg]: 'JPEG',
  [documentFormats.tiff]: 'TIFF',
  [documentFormats.pdf]: 'PDF',
  [documentFormats.vsm]: 'VSM',
  [documentFormats.biologicalReport]: 'XD-LAB',
};

export const documentFormatTypeMimes = {
  [documentFormats.textPlain]: 'text/plain',
  [documentFormats.richTextFormat]: 'text/rtf',
  [documentFormats.jpeg]: 'image/jpeg',
  [documentFormats.tiff]: 'image/tiff',
  [documentFormats.pdf]: 'application/pdf',
  [documentFormats.vsm]: 'text/html',
  [documentFormats.biologicalReport]: 'text/html',
};

export const documentFormatExtensionsUpload = {
  [documentFormats.textPlain]: ['txt'],
  [documentFormats.richTextFormat]: ['rtf'],
  [documentFormats.jpeg]: ['jpeg', 'jpg'],
  [documentFormats.tiff]: ['tiff'],
  [documentFormats.pdf]: ['pdf'],
};
// [documentFormats.vsm]: ['html'],
// [documentFormats.biologicalReport]: ['html'],

export const documentFormatExtensions = {
  [documentFormats.textPlain]: 'txt',
  [documentFormats.richTextFormat]: 'rtf',
  [documentFormats.jpeg]: 'jpeg',
  [documentFormats.tiff]: 'tiff',
  [documentFormats.pdf]: 'pdf',
  [documentFormats.vsm]: 'html',
  [documentFormats.biologicalReport]: 'html',
};

export const documentFormatPreviews = {
  [documentFormats.textPlain]: true,
  [documentFormats.richTextFormat]: true,
  [documentFormats.jpeg]: true,
  [documentFormats.tiff]: true,
  [documentFormats.pdf]: true,
  [documentFormats.vsm]: true,
  [documentFormats.biologicalReport]: true,
};

export const dmpCategories = [
  {
    name: 'Synthèses',
    types: ['SYNTH', '34133-9'],
  },
  {
    name: 'Traitements et soins',
    types: ['57833-6', '60593-1', '11506-3', '57832-8', 'DISP_AUT', '57828-6', '18776-5', 'PROT_ALD'],
  },
  {
    name: 'Comptes-rendus',
    types: [
      '11490-0', '11488-4', '70004-7', '75497-8', '34749-2', '47420-5', '34794-8', '67851-6',
      '34874-8', '77436-4', '34112-3', 'BIL_AUTO', '15508-5', '15507-7',
    ],
  },
  {
    name: 'Imageries médicales',
    types: ['18748-4', '52040-3'],
  },
  {
    name: 'Biologie',
    types: ['11502-2', '11526-1'],
  },
  {
    name: 'Prévention',
    types: ['75482-0', '75492-9'],
  },
  {
    name: 'Certificats et Déclarations',
    types: ['CERT_DECL'],
  },
  {
    name: 'Documents externes',
    types: ['EXPPAT_2', 'EXPPAT_1', 'REMB'],
  },
];

export const dateRangePresets = [
  {
    code: 'today',
    label: 'Aujourd\'hui',
    from: moment().format('DD/MM/YYYY'),
    to: '',
    bar: false,
    picker: true,
  },
  {
    code: 'yesterday',
    label: 'Hier',
    from: moment().subtract(1, 'days').format('DD/MM/YYYY'),
    to: '',
    bar: false,
    picker: true,
  },
  {
    code: '1-week',
    label: '1 semaine',
    from: moment().subtract(7, 'days').format('DD/MM/YYYY'),
    to: '',
    bar: true,
    picker: true,
  },
  {
    code: '1-month',
    label: '1 mois',
    from: moment().subtract(30, 'days').format('DD/MM/YYYY'),
    to: '',
    bar: true,
    picker: true,
  },
  {
    code: '2-months',
    label: '2 mois',
    from: moment().subtract(30 * 2, 'days').format('DD/MM/YYYY'),
    to: '',
    bar: false,
    picker: true,
  },
  {
    code: '6-months',
    label: '6 mois',
    from: moment().subtract(30 * 6, 'days').format('DD/MM/YYYY'),
    to: '',
    bar: false,
    picker: true,
  },
  {
    code: '1-year',
    label: '1 an',
    from: moment().subtract(365, 'days').format('DD/MM/YYYY'),
    to: '',
    bar: true,
    picker: true,
  },
  {
    code: '3-years',
    label: '3 ans',
    from: moment().subtract(365 * 3, 'days').format('DD/MM/YYYY'),
    to: '',
    bar: true,
    picker: true,
  },
];

export const OIDS = {
  NORMAL: '1.2.250.1.213.1.4.8',
  NIA: '1.2.250.1.213.1.4.9',
};
