import { dmpconnectConfigurationActionConstants } from '../constants';

export const setDisplayConfiguration = (key, value) => ({
  type: dmpconnectConfigurationActionConstants.DMPC_SET_DISPLAY_CONFIGURATION,
  key,
  value,
});
export const resetDisplayConfigurationFromEnv = payload => ({
  type: dmpconnectConfigurationActionConstants.DMPC_RESET_DISPLAY_CONFIGURATION_FROM_ENV,
  payload,
});

export const setPersistedAppConfiguration = (key, value) => ({
  type: dmpconnectConfigurationActionConstants.DMPC_SET_PERSIST_APP_CONFIGURATION,
  key,
  value,
});


export const resetPersistedAppConfigurationFromEnv = () => ({
  type: dmpconnectConfigurationActionConstants.DMPC_RESET_PERSIST_APP_CONFIGURATION_FROM_ENV,
});

export const resetAllConfigFromEnv = () => ({
  type: dmpconnectConfigurationActionConstants.DMPC_RESET_ALL_CONFIGURATION_FROM_ENV,
});
