import { getErrorDescription } from '../../errors';

export const isLoading = (section) => {
  if (!section) return false;
  const { loading } = section;
  return loading === true;
};

export const isTooLong = (section) => {
  if (!section) return false;
  const { loading, tooLong } = section;
  return loading === true && tooLong === true;
};

export const isReady = (section) => {
  if (!section) return false;
  const { s_status } = section;
  return s_status === 'OK';
};

export const getError = (section) => {
  if (!section) return null;
  const { error = null } = section;
  return error;
};

export const hasError = (section) => {
  if (!section) return false;
  const { error = null } = section;
  return error !== null;
};
export const isModalError = (error) => {
  const { modal } = getErrorDescription(error);
  return modal;
};

export const mergeSubSectionsStatuses = (section) => {
  const subSectionArray = Object.values(section || {});
  if (subSectionArray.length === 0) {
    return {
      loading: false,
      ready: false,
      hasError: false,
      errors: null,
    };
  }
  return {
    loading: subSectionArray.some(sub => isLoading(sub)),
    ready: subSectionArray.every(sub => isReady(sub)),
    hasError: subSectionArray.some(sub => hasError(sub)),
    errors: subSectionArray.map(sub => getError(sub)),
  };
};
