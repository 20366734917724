import base64 from 'base-64';
import * as yup from 'yup';
import { isReady } from '../common';
import { extractDate } from '../index';
import { validationSchema } from './checkIdentity';
import { getObjectKeyFromPath } from '../../utils/dataUtils';


export const batchIsDone = batch => [1, 2, 4].includes(batch.batchValidationResult);
export const allBatchesDone = batches => batches && batches.every(b => batchIsDone(b));
export const countBatchesDone = batches => batches.reduce((count, batch) => (batchIsDone(batch) ? count + 1 : count), 0);
export const someBatchesDoneButInError = batches => batches.some(b => [2, 4].includes(b.batchValidationResult));
export const countAllIdentities = batches => batches.reduce((count, batch) => count + batch.size, 0);
export const getMaxEstimatedResultUnixTimestamp = batches => batches.reduce((timestamp, batch) => {
  if (batch.estimatedResultUnixTimestamp > timestamp) return batch.estimatedResultUnixTimestamp;
  return timestamp;
}, 0);
export const getMaxIdentities = batches => batches.reduce((max, batch) => {
  if (batch.size > max) return batch.size;
  return max;
}, 0);
export const allResultsReady = (batches) => {
  const maxTimestamp = getMaxEstimatedResultUnixTimestamp(batches);
  return maxTimestamp > 0 && maxTimestamp <= (Date.now() / 1000);
};

export const splitArray = (array, maxLentgth) => {
  if (array.length <= maxLentgth) {
    return [array];
  }
  const parts = [];
  while (array.length) {
    parts.push(array.splice(0, maxLentgth));
  }
  return parts;
};

const batchValidationSchema = yup.object({
  batchId: yup.string().required('Ce champ est requis'),
  identities: yup.array().of(validationSchema),
});

export const validateIdentities = (identities, nbMin) => {
  const errors = [];
  try {
    batchValidationSchema.validateSync(identities, { abortEarly: false });
  } catch (validationError) {
    validationError.inner.forEach((error) => {
      const pathParts = error.params.path.split('.');
      const identity = getObjectKeyFromPath(pathParts[0], identities);
      errors.push({
        field: pathParts[1],
        value: error.params.value,
        error: error.message,
        identity,
      });
    });
  }
  return errors;
};

export const formatIdentity = identities => identities.map(identity => ({
  s_ins: identity.ins,
  s_key: identity.key,
  s_birthName: identity.name.toUpperCase().replace(/\s+/g, ' ').replace(/-+/g, '-'),
  s_given: identity.given.toUpperCase().replace(/\s+/g, ' ').replace(/-+/g, '-'),
  s_birthGiven: identity.given.toUpperCase().replace(/\s+/g, ' ').replace(/-+/g, '-'),
  i_sex: Number(identity.sex),
  s_birthDate: identity.birthday,
  s_birthPlace: identity.birthplace,
  s_oid: identity.oid,
}));

export const extractBatchesFromPersistantData = (
  psId,
  persistantData,
) => {
  try {
    const decodedPersistantData = JSON.parse(base64.decode(persistantData));
    const {
      batches: {
        [psId]: {
          batchId = null,
          exported = false,
          batches = [],
          identities = [],
        } = {},
      } = {},
    } = decodedPersistantData;
    return {
      batchId, exported, batches, identities,
    };
  } catch (e) {
    return { error: e.message };
  }
};

export const generateNewPersistantData = ({
  psId,
  clear = false,
  parentBatchId,
  batch,
  identities,
  exported,
  previousPersistantData,
}) => {
  let newPersistantData;
  if (previousPersistantData) {
    const decodedPersistantData = JSON.parse(base64.decode(previousPersistantData || ''));
    const {
      batches: {
        [psId]: {
          exported: extractedExported = false,
          batches = [],
          identities: decodedIdentities = [],
        } = {},
        ...restBatches
      } = {},
      ...rest
    } = decodedPersistantData;


    if (clear === true) {
      newPersistantData = {
        ...rest,
        batches: {
          ...restBatches,
          [psId]: {
            batchId: null,
            exported: false,
            batches: [],
            identities: [],
          },
        },
      };
    } else {
      const newBatches = [...batches];
      let newIdentities = [...(identities || decodedIdentities)];
      if (batch) {
        const index = newBatches.findIndex(b => b.id === batch.id);

        if (index !== -1) {
          newBatches[index] = { ...newBatches[index], ...batch };
        } else {
          newBatches.push(batch);
        }

        if (batchIsDone(batch) && batch.identities && batch.identities.length > 0) {
          newIdentities = newIdentities.map((identity) => {
            const result = batch.identities.find(r => identity.ins === r.ins
              && identity.key === r.key
              && identity.oid === r.oid);

            return result || identity;
          });
        }
      }

      newPersistantData = {
        ...rest,
        batches: {
          ...restBatches,
          [psId]: {
            batchId: parentBatchId,
            exported: exported || extractedExported,
            batches: newBatches,
            identities: newIdentities,
          },
        },
      };
    }
  } else {
    newPersistantData = {
      batches: {
        [psId]: {
          batchId: parentBatchId,
          batches: batch ? [batch] : [],
          identities: identities || [],
        },
      },
    };
  }
  return newPersistantData;
};

export const generateBatchFromResult = (internalId, result) => {
  const processDate = extractDate(result.s_processingDate, 'YYYY-MM-DDTHH:mm:ss.SSSZZ', false);
  return {
    id: internalId,
    batchId: result.s_batchId,
    delay: result.i_estimationDelay,
    processingDate: processDate.format('DD/MM/YYYY HH:mm:ss'),
    estimatedResultUnixTimestamp: processDate.add(result.i_estimationDelay, 's').unix(),
    estimatedResultDate: processDate.format('DD/MM/YYYY HH:mm:ss'),
    size: result.i_requestSize,
  };
};

export const parseInsiWS4Result = (section) => {
  const toDelete = [];
  const addedPersistantData = [];
  let error = false;
  Object.entries(section).forEach(([id, subSection]) => {
    if (!error && isReady(subSection)) {
      addedPersistantData.push({
        ...generateBatchFromResult(id, subSection),
        identities: subSection.params.identities,
      });
      toDelete.push(id);
    } else {
      error = false;
    }
  });
  if (error) {
    return { error: true };
  }

  return { subSectionsToDelete: toDelete, newBatches: addedPersistantData };
};

export const parseInsiWS5Result = (result, subSection) => {
  if (isReady(result)) {
    if (result.i_batchValidationResult === 3) {
      const processDate = extractDate();
      return {
        id: subSection,
        batchValidationResult: result.i_batchValidationResult,
        batchId: result.batchId,
        delay: result.i_estimationDelay,
        estimatedResultUnixTimestamp: processDate.add(result.i_estimationDelay, 's').unix(),
        estimatedResultDate: processDate.format('DD/MM/YYYY HH:mm:ss'),
      };
    }
    return {
      id: subSection,
      batchValidationResult: result.i_batchValidationResult,
      batchId: result.batchId,
      identities: result.params.identities.map((identity) => {
        const resultIdentity = result.Result.find(r => identity.ins === r.s_ins
          && identity.key === r.s_key
          && identity.oid === r.s_oid);
        return { ...identity, status: resultIdentity ? resultIdentity.i_validationResult : 2 };
      }),
    };
  }
  return null;
};

export function exportIdentities(batchExportUrl, batchId, results) {
  return fetch(batchExportUrl, {
    method: 'post',
    body: JSON.stringify({
      file: {
        mime: '@file/json',
        data: base64.encode(JSON.stringify({
          batchId,
          results,
        })),
      },
    }),
  });
}
