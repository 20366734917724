import React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { encodeIns } from 'dmpconnectjsapp-base/utils/insUtils';
import { setPinCode } from '../../dmpconnect/actions';

const IframeMessageReceiver = ({ dispatch }) => {
  React.useEffect(() => {
    const handler = (event) => {
      try {
        const data = JSON.parse(event.data);
        const { pinCode } = data;
        if (pinCode) {
          dispatch(setPinCode(null));
          dispatch(setPinCode(encodeIns(pinCode)));
        }
      } catch (e) {}
    };

    window.addEventListener('message', handler);

    // clean up
    return () => window.removeEventListener('message', handler);
  });
  return null;
};
IframeMessageReceiver.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(IframeMessageReceiver);
