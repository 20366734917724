import {
  call, delay, put, race, select, take,
} from 'redux-saga/effects';
import { getConfigurationValue } from 'dmpconnectjsapp-base/helpers/accessors';
import {
  clearSection,
  dmpCommandFailure, dmpCommandFailureContextualized,
  dmpCommandSuccess, dmpCommandSuccessContextualized,
  insi_getBatchResult,
  saveBatchInPersistantData, saveExportedInPersistantData, stopAllGetResults,
} from '../actions';
import {
  batchIsDone, exportIdentities,
  generateBatchFromResult,
  parseInsiWS5Result,
} from '../helpers/insi/batch';
import { apiSections } from '../constants/apiConstants';
import { getSuccessState } from '../reducers';
import { createError } from '../errors';
import { softwareErrors } from '../errors/errorConstants';
import { errorTypes } from '../errors/errorConfiguration';
import { dmpconnectActionConstants } from '../constants';

const getBatchExportUrl = ({ dmpConnectPersistedAppConfiguration }) => getConfigurationValue(
  'batchExportUrl',
  dmpConnectPersistedAppConfiguration,
);
const getBatchAutoProcess = ({ dmpConnectPersistedAppConfiguration }) => getConfigurationValue(
  'batchAutoProcess',
  dmpConnectPersistedAppConfiguration,
);

function* getResult(delayInSeconds, subSection, batchId, parentBatchId, identities) {
  yield delay(delayInSeconds);
  yield put(insi_getBatchResult(subSection, batchId, parentBatchId, identities));
}

export function* handleINSiBatchSubmitResult(action) {
  const batchAutoProcess = yield select(getBatchAutoProcess);
  const {
    context: { subSection, parentBatchId, params: { identities } },
    data,
  } = action;
  yield put(saveBatchInPersistantData({
    parentBatchId,
    batch: {
      ...generateBatchFromResult(subSection, data),
      identities,
    },
  }));
  if (batchAutoProcess === true) {
    // yield call(getResult, 3 * 1000, subSection, data.s_batchId, parentBatchId, identities);
    // yield call(getResult, data.i_estimationDelay * 1000, subSection, data.s_batchId, parentBatchId, identities);
    yield race({
      task: call(getResult, data.i_estimationDelay * 1000, subSection, data.s_batchId, parentBatchId, identities),
      cancel: take(dmpconnectActionConstants.DMPC_INSI_STOP_ALL_GET_RESULTS),
    });
  }
}

export function* handleINSiBatchGetResult(action) {
  const batchAutoProcess = yield select(getBatchAutoProcess);
  const {
    command: { s_batchId },
    context: { subSection, parentBatchId, params: { identities } },
    data,
  } = action;

  const {
    [apiSections.INSI_RESULT_IDENTITY_BATCH]: {
      [subSection]: result,
    },
  } = getSuccessState(action);

  yield put(saveBatchInPersistantData({
    parentBatchId,
    batch: parseInsiWS5Result({ ...result, batchId: s_batchId }, subSection),
  }));
  if (batchAutoProcess === true) {
    // if result is still pending, call getResult again after the new delay
    if (data.i_batchValidationResult === 3) {
      // yield call(getResult, 3 * 1000, subSection, s_batchId, parentBatchId, identities);
      yield race({
        task: call(getResult, data.i_estimationDelay * 1000, subSection, s_batchId, parentBatchId, identities),
        cancel: take(dmpconnectActionConstants.DMPC_INSI_STOP_ALL_GET_RESULTS),
      });
    }
  }
}

export function* checkPendingBatches({ batches, batchId }) {
  const batchAutoProcess = yield select(getBatchAutoProcess);
  if (batchAutoProcess === true) {
    if (batches) {
      yield put(stopAllGetResults());
      // if batches are still pending, get the results
      for (let i = 0; i < batches.length; i += 1) {
        const batch = batches[i];
        if (!batchIsDone(batch)) {
          const estimatedDelay = batch.estimatedResultUnixTimestamp - (Date.now() / 1000);
          // yield call(getResult, estimatedDelay, batch.id, batch.batchId, batchId, batch.identities);
          yield race({
            task: call(getResult, estimatedDelay * 1000, batch.id, batch.batchId, batchId, batch.identities),
            cancel: take(dmpconnectActionConstants.DMPC_INSI_STOP_ALL_GET_RESULTS),
          });
        }
      }
    }
  }
}

export function* exportResults(action) {
  yield put(clearSection(apiSections.INSI_EXPORT_IDENTITY_BATCH));
  const batchExportUrl = yield select(getBatchExportUrl);
  const { batchId, result } = action;
  try {
    const response = yield call(exportIdentities, batchExportUrl, batchId, result);
    if (response.ok) {
      const data = yield call([response, response.json]);

      yield put(saveExportedInPersistantData({
        parentBatchId: batchId,
        exported: true,
      }));
      yield put(dmpCommandSuccess({ s_status: 'OK' }, { section: apiSections.INSI_EXPORT_IDENTITY_BATCH }, result));
      yield put(dmpCommandSuccessContextualized(data, { section: apiSections.INSI_EXPORT_IDENTITY_BATCH }, result));
    } else {
      throw new Error(`${response.status} ${response.statusText}`);
    }
  } catch (e) {
    yield put(dmpCommandFailure(
      { message: e.message, ...createError(errorTypes.SoftwareErrors, softwareErrors.INSI_EXPORT_ERROR) },
      { section: apiSections.INSI_EXPORT_IDENTITY_BATCH },
      result,
    ));
    yield put(dmpCommandFailureContextualized(
      { message: e.message, ...createError(errorTypes.SoftwareErrors, softwareErrors.INSI_EXPORT_ERROR) },
      { section: apiSections.INSI_EXPORT_IDENTITY_BATCH },
      result,
    ));
  }
}
