export const errorActions = {
  CLOSE_SESSION: 'CLOSE_SESSION',
  REFRESH_PAGE: 'REFRESH_PAGE',
  GO_TO_DASHBOARD: 'GO_TO_DASHBOARD',
  LOGIN: 'LOGIN',
  NONE: 'NONE',
};

export const errorActionsMessages = {
  [errorActions.CLOSE_SESSION]: 'Redémarrer l\'application',
  [errorActions.REFRESH_PAGE]: 'Rafraîchir la page',
  [errorActions.GO_TO_DASHBOARD]: 'Retourner au dashboard',
  [errorActions.LOGIN]: 'Se reconnecter',
  [errorActions.NONE]: 'OK',
};

export const executeErrorAction = (errorDescription) => {
  switch (errorDescription.action) {
    case errorActions.CLOSE_SESSION:
    case errorActions.LOGIN:
      window.location = '/';
      break;
    case errorActions.REFRESH_PAGE:
      window.location.reload();
      break;
    case errorActions.GO_TO_DASHBOARD:
      window.location = '/search/';
      break;
    default:
      break;
  }
};
