export const routes = {
  logout: { path: '/logout' },
  home: { path: '/' },
  dmpSearch: { path: '/search' },
  configuration: { path: '/configuration/:tab?' },
  debug: { path: '/debug' },
  tseConfig: { path: '/tse-config' },
  esConfig: { path: '/es-config' },
  logs: { path: '/logs' },
};
