import { dmpconnectApplicationActionConstants } from '../constants';


export const setModalError = modalError => ({
  type: dmpconnectApplicationActionConstants.DMPC_SET_MODAL_ERROR,
  modalError,
});

export const clearModalError = () => ({
  type: dmpconnectApplicationActionConstants.DMPC_CLEAR_MODAL_ERROR,
});

export const setPanel = (panel, newSearchPanel) => ({
  type: dmpconnectApplicationActionConstants.DMPC_SET_PANEL,
  panel,
  newSearchPanel,
});

export const monitorLicenseAfterForm = () => ({
  type: dmpconnectApplicationActionConstants.DMPC_LICENSE_CHECK,
});


export const setExportId = exportId => ({
  type: dmpconnectApplicationActionConstants.DMPC_SET_EXPORT_ID,
  exportId,
});

export const setUrlProcessed = processed => ({
  type: dmpconnectApplicationActionConstants.DMPS_SET_URL_PROCESSED,
  processed,
});

export const setIsFirstLogin = value => ({
  type: dmpconnectApplicationActionConstants.SET_IS_FIRST_LOGIN,
  value,
});

export const setLoginTab = loginTab => ({
  type: dmpconnectApplicationActionConstants.SET_LOGIN_TAB,
  loginTab,
});
