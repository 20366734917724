export const parseXmlToJsResult = (xml, startValue = {}) => {
  const nodes = Array.isArray(xml) ? xml : Object.entries(xml);
  return nodes.reduce((parsedXml, [nodeName, nodeValue]) => {
    const {
      _attributes: attributes, _text: text, _cdata: cdata, ...children
    } = nodeValue;

    if (Array.isArray(nodeValue)) {
      return {
        ...parsedXml,
        [nodeName]: Object.values(parseXmlToJsResult(children)),
      };
    }

    const value = text || cdata;
    let parsedChildren;
    if (Object.keys(children).length > 0) {
      parsedChildren = parseXmlToJsResult(children);
      return {
        ...parsedXml,
        [nodeName]: parsedChildren,
      };
    }

    return {
      ...parsedXml,
      [nodeName]: value,
    };
  }, startValue);
};
