import base64 from 'base-64';
import utf8 from 'utf8';
import * as blobUtil from 'blob-util';
import UTIF from 'utif';
import {
  documentFormatExtensions,
  documentFormatExtensionsUpload,
  documentFormatTypeMimes,
} from '../constants';

export const hashCode = (string) => {
  let hash = 0;

  for (let i = 0; i < string.length; i += 1) {
    const char = string.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash &= hash; // Convert to 32bit integer
  }
  return hash;
};

/**
 * Generate DATA URI
 * @param content
 * @param documentFormat
 * @returns {string}
 */
export const generateDataURI = (content, documentFormat) => {
  // if unknown format > text/html refs #1359
  let typeMime = documentFormatTypeMimes[documentFormat] || 'text/html';
  const data = content.replace(/[\n\r]+/g, '');

  if (typeMime === 'text/plain') {
    typeMime += ';charset=UTF-8';
  }
  return `data:${typeMime};base64,${data}`;
};

/**
 * Decode unicode
 * @param str
 * @returns {*|string|number[]|Promise<void>}
 */
export const b64DecodeUnicode = (str) => {
  const data = str.replace(/[\n\r]+/g, '');
  const bytes = base64.decode(data);
  try {
    return utf8.decode(bytes);
  } catch (error) {
    return bytes;
  }
};

/**
 * base64ToArrayBuffer
 * @param base64ToBufferized
 * @returns {ArrayBufferLike}
 */
export const base64ToArrayBuffer = (base64ToBufferized) => {
  const binaryString = window.atob(base64ToBufferized);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i += 1) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};

/**
 * download document for Internet Explorer
 * @param content
 * @param format
 * @param title
 */
export const downloadDocumentWithIE = (content, format, title) => {
  // if unknown format > text/html refs #1359
  const typeMime = documentFormatTypeMimes[format] || 'text/html';
  const extension = documentFormatExtensions[format] || 'html';
  const data = content.replace(/[\n\r]+/g, '');
  const blob = blobUtil.base64StringToBlob(data, typeMime);
  const filename = `${title}.${extension}`;
  window.navigator.msSaveOrOpenBlob(blob, filename);
};

/**
 * @param typeMime
 * @returns {string}
 */
export const getDocumentFormatByMimeType = (typeMime) => {
  const foundEntry = Object.entries(documentFormatTypeMimes).find(entry => entry[1] === typeMime);
  return foundEntry[0];
};

export const getDocumentFormatByExtension = (fileName) => {
  const [extension] = fileName.split('.').reverse();
  const formats = Object.entries(documentFormatExtensionsUpload).filter(entry => entry[1].includes(
    extension,
  ));
  return formats;
};

/**
 * Taken from https://github.com/photopea/UTIF.js
 * TIFF arrayBuffer to PNG base64
 * @param arrayBuffer
 * @returns {string}
 */
export const tiffToPng = (arrayBuffer) => {
  const ifds = UTIF.decode(arrayBuffer);
  let vsns = ifds;
  let ma = 0;
  let
    page = vsns[0];
  if (ifds[0].subIFD) vsns = vsns.concat(ifds[0].subIFD);
  for (let i = 0; i < vsns.length; i++) {
    const img = vsns[i];
    if (img.t258 == null || img.t258.length < 3) continue;
    const ar = img.t256 * img.t257;
    if (ar > ma) {
      ma = ar;
      page = img;
    }
  }
  UTIF.decodeImage(arrayBuffer, page, ifds);
  const rgba = UTIF.toRGBA8(page);
  const w = page.width;
  const
    h = page.height;
  const cnv = document.createElement('canvas');
  cnv.width = w;
  cnv.height = h;
  const ctx = cnv.getContext('2d');
  const
    imgd = ctx.createImageData(w, h);
  for (let i = 0; i < rgba.length; i++) imgd.data[i] = rgba[i];
  ctx.putImageData(imgd, 0, 0);
  return cnv.toDataURL();
};

// function dec2hex(dec) {
//   return (`0${dec.toString(16)}`).substr(-2);
// }
export function generateId(len) {
  // const arr = new Uint8Array((len || 40) / 2);
  // window.crypto.getRandomValues(arr);
  // return Array.from(arr, dec2hex).join('');

  return `${Math.random().toString(36).substr(2, 9)}-${Math.random().toString(36).substr(2, 9)}`;
}

export function objectToQueryParams(params) {
  return Object.entries(params).map(([key, value]) => {
    if (value) {
      return `${key}=${encodeURIComponent(value)}`;
    }

    return undefined;
  }).join('&');
}

export function getObjectKeyFromPath(key, obj) {
  // transform key : path[0].name => path.0.name
  const newKey = key.replace(/\[(\w+)\]/g, '.$1');
  return newKey.split('.').reduce((a, b) => a && a[b], obj);
}

export const objectWithoutTheseKeys = (object, keys) => Object.entries(object).reduce((result, [key, value]) => {
  if (!keys.includes(key)) {
    return { ...result, [key]: value };
  }
  return result;
}, {});

export function parseQueryString(query) {
  return query.split('&').reduce((result, item) => {
    const parts = item.split('=');
    return { ...result, [parts[0]]: parts[1] };
  }, {});
}

export function mergeSearchAndHash(search, hash) {
  const toMerge = [];
  if (search) toMerge.push(search);
  if (hash) toMerge.push(hash);
  return toMerge.join('&');
}

export function capitalize(str1) {
  return str1.charAt(0).toUpperCase() + str1.slice(1);
}

export const isUrlValid = url => url.match(/http(s)?:\/\/.(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}(\.[a-z]{2,6}\b)?([-a-zA-Z0-9@:%_+.~#?&//=]*)/g);
