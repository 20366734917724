import * as yup from 'yup';
import moment from 'moment';
import 'moment/locale/fr';

yup.addMethod(yup.string, 'date', function () {
  return this.test('valid-date', 'Date invalide', function (value) {
    if (value === '' || value === undefined) return true;
    const date = moment(value, ['DD/MM/YYYY', 'YYYY-MM-DD'], true);
    const isRankValid = date.year() >= 1600 && date.year() <= 2100;
    const { createError } = this;
    return (date.isValid() && isRankValid) || createError('Date invalide');
  });
});

yup.addMethod(yup.string, 'pastDate', function () {
  return this.test('valid-past-date', 'La date ne peux être dans le futur', (value) => {
    if (value === '' || value === undefined) return true;
    const date = moment(value, ['DD/MM/YYYY', 'YYYY-MM-DD'], true);
    return date < new Date();
  });
});
yup.addMethod(yup.string, 'DigitsOnly', function () {
  return this.uppercase().matches(/^[0-9]*$/,
    'Ne peut contenir que des chiffres');
});
yup.addMethod(yup.string, 'DMPCMaxLength', function (length = 80) {
  return this.max(length, `Ce champ ne peut excéder ${length} caractères`);
});

yup.addMethod(yup.string, 'INSiNames', function () {
  return this.uppercase()
    .matches(/^[A-Z']{1}[A-Z'\- ]*$/, 'Caratères autorisés : lettres majuscules, tiret (-), apostrophe (\'), espace ( )')
    .matches(/^(?:(?!\s{2}).)*$/, 'Les espaces ne peuvent être doublonnés')
    .matches(/^(?:(?!'{2}).)*$/, 'Les apostrophes ne peuvent être doublonnés');
});
