import moment from 'moment';
import 'moment/locale/fr';

export const getCurrentPathname = (state) => {
  const { router: { location: { pathname } } } = state;
  return pathname;
};

export const getCpxCardStatus = (state) => {
  const { dmpconnect } = state;
  const { cpxCard } = dmpconnect;
  return cpxCard.s_status;
};


export const getDmpconnectCpxConfiguration = (state) => {
  const { dmpconnectCPxConfiguration } = state;
  return dmpconnectCPxConfiguration;
};
export const getDmpconnectESConfiguration = (state) => {
  const { dmpconnectESConfiguration } = state;
  return dmpconnectESConfiguration;
};
export const getDmpconnectPersistedConfiguration = (state) => {
  const { dmpConnectPersistedAppConfiguration } = state;
  return dmpConnectPersistedAppConfiguration;
};

export const getSessionId = (state) => {
  const { dmpconnect } = state;
  const { session } = dmpconnect;
  return session.s_sessionId;
};

export const isUserLoggedIn = (state) => {
  const { dmpconnectUser } = state;
  return dmpconnectUser.loggedIn;
};

export const getLoginReferer = (state) => {
  const { dmpconnectUser } = state;
  return dmpconnectUser.loginReferer;
};

export const extractDate = (date, format = 'DD/MM/YYYY', utc = true) => {
  if (date && format) {
    return utc ? moment.utc(date, [format], true) : moment(date, [format], true);
  }
  return moment();
};

export const formatDate = (date, format = 'DD/MM/YYYY') => date.format(format);

export const getEsUserFromConfig = (dmpConfiguration) => {
  if (dmpConfiguration) {
    return {
      hpAuthenticationMode: dmpConfiguration.hpAuthenticationMode || 8,
      hpGiven: dmpConfiguration.hpGiven || 'Spécialiste',
      hpInternalId: dmpConfiguration.hpInternalId || 'INT14440',
      hpName: dmpConfiguration.hpName || 'Robert',
      hpProfession: dmpConfiguration.hpProfession || '10',
      hpProfessionOid: dmpConfiguration.hpProfessionOid || '1.2.250.1.71.1.2.7',
      hpSpeciality: dmpConfiguration.hpSpeciality || 'SM26',
      esId: dmpConfiguration.es_id,
    };
  }
  return null;
};

export const getEsUserMetaDatas = (user) => {
  if (!user) return {};
  const {
    profile: metadata = {},
  } = user;
  return metadata;
};
