import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import 'react-toastify/dist/ReactToastify.css';
import 'line-awesome/dist/line-awesome/css/line-awesome.min.css';
import './scss/main.scss';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';

// TODO : remove Router ???
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import { LastLocationProvider } from 'react-router-last-location';

import App from './App';
import configureStore, { history, sagaMiddleware } from './store';
import rootSaga from './dmpconnect/sagas/rootSaga';

export const { store, persistor } = configureStore();

sagaMiddleware.run(rootSaga);

render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <LastLocationProvider>
          <App history={history} />
        </LastLocationProvider>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('app'),
);
